import React, { useEffect, useState, useRef } from "react";
import { companyLogo } from '../../../assets/images'
import { Link, useNavigate } from 'react-router-dom'

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import { galaxyKids } from "../../../assets/styles/globle";
import { UserOutlined } from "@ant-design/icons";
import GoogleLoginButton from '../../GoogleLoginButton';
import { message } from "antd";
import axios from "axios";
import { Form, Input, Button } from "antd";
import { toast } from "react-toastify";
import Select from 'react-select';

import { useSettings } from '../../../context/CountryContext';
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { Divider } from "antd";

const center = {
    lat: 37.7749, // Default latitude (e.g., San Francisco)
    lng: -122.4194, // Default longitude
};

const libraries = ["places"];


export default function Header() {
    const closeButtonRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState(false);
    const [accessTokenParent, setAccessTokenViaLogin] = useState(null);

    const [mapCenter, setMapCenter] = useState(center);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLat, setLat] = useState(null);
    const [selectedLong, setLong] = useState(null);

    const [isLogin, setIsLogin] = useState(true); // State to toggle between Login and Register
    const [name, setName] = useState(""); // For Register]

    const languageOptions = [];
    const { configuredSettings, loading } = useSettings();
    configuredSettings?.data?.languages.map((data) => {
        languageOptions.push({ value: data, label: data });
    })

    useEffect(() => {
        const interval = setInterval(() => {
            const token = localStorage.getItem("accessToken");
            setAccessToken(!!token);
        }, 5000); // Check every second

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            setAccessToken(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        localStorage.removeItem("accessToken");
        navigate("/login");
    };

    const handleSuccess = (response) => {
        if (response?.data?.statusCode === 200) {
            localStorage.setItem("accessToken", response?.data?.data?.accessToken);
            localStorage.setItem("teacherId", response?.data?.data?.teacher?._id);
            if (response?.data?.data?.teacher?.isVerified) {
                navigate("/");
                setAccessToken(true);
            } else {
                navigate("/");
            }
        }
    };

    const handleError = (error) => {
        //console.error('Login failed:', error);
        message.error(error?.response?.data?.message);
    };

    const handleLoginSubmit = async (values) => {
        const { email, password } = values;
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/parents/login`, { email, password });
            if (response.data.success && response.data.statusCode === 200) {
                setAccessToken(true);
                setAccessTokenViaLogin(response.data.data.accessToken);
                const accessToken = response?.data?.data?.accessToken;
                const parentId = response?.data?.data?.data?._id;
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("parentId", parentId);
                toast.success(response?.data?.message);
                if (closeButtonRef.current) {
                    closeButtonRef.current.click();
                }
                navigate("/");
            }
        } catch (error) {
            //message.error(error?.response?.data?.message);
            toast.error(error?.response?.data?.message);
        }
    };


    const handleSubmit = async (values) => {

        values.isVerified = false;
        const formattedCoordinates = [
            parseFloat(selectedLong).toFixed(4),
            parseFloat(selectedLat).toFixed(4),
        ];
        values.location = { coordinates: formattedCoordinates, type: "Point" };
        values.address = {
            city: selectedCity,
            state: selectedState,
            country: selectedCountry
        }
        const data = {
            ...values,
            language_skills: [
                {
                    language: values.language.label,
                    dialect: "",
                },
            ],
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/parents/register`,
                data
            );
            const accessToken = response?.data?.data?.accessToken;
            const parentId = response?.data?.data?.data?._id;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("parentId", parentId);
            toast.success(response?.data?.message);

            if (closeButtonRef.current) {
                closeButtonRef.current.click();
            }

            navigate("/");
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    const onPlaceChanged = async () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();

            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            const result = extractAddressComponents(place.address_components);
            form.setFieldsValue({ location: result.country });
            setSelectedCountry(result.country)
            setSelectedState(result.state)
            setSelectedCity(result.city)
            setLat(lat);
            setLong(lng);
            await setMapCenter({ lat, lng });
        }
    };

    function extractAddressComponents(addressComponents) {
        let area = null;
        let streetAddress = null;
        let apartmentNo = null;
        let country = null;
        let state = null;
        let city = null;
        let latitude = null;
        let longitude = null;

        addressComponents.forEach(component => {
            if (component.types.includes("locality")) {
                city = component.long_name; // City (e.g., "London")
            } else if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name; // State/Province (e.g., "California")
            } else if (component.types.includes("country")) {
                country = component.long_name; // Country (e.g., "United States")
            } else if (component.types.includes("sublocality_level_1") || component.types.includes("sublocality")) {
                area = component.long_name; // Sub-locality (e.g., "Marylebone")
            } else if (component.types.includes("route")) {
                streetAddress = component.long_name; // Street name (e.g., "Baker Street")
            } else if (component.types.includes("street_number")) {
                streetAddress = `${component.long_name} ${streetAddress || ""}`; // Street number + name
            } else if (component.types.includes("subpremise")) {
                apartmentNo = component.long_name; // Apartment/Villa No (e.g., "Flat 2")
            }
        });

        return { area, streetAddress, apartmentNo, country, state, city };
    }

    const onLoad = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    };

    return (
        <header className='header'>
            <nav className="navbar navbar-expand-lg py-4">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src={companyLogo} alt='company logo' width="180" />
                    </Link>
                    <button className="navbar-toggler border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="#" className="nav-link px-4 active" aria-current="page" href="#">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="#" className="nav-link px-4" href="#">School Version</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="#" className="nav-link px-4" href="#">English</Link>
                            </li>
                            {accessToken ? (
                                <>
                                    <li className='nav-button'>
                                        <button className='btn btn-outline-primary ms-2' onClick={() => navigate("/")} >Home</button>
                                    </li>
                                    <UncontrolledDropdown nav inNavbar className="d-md-block d-none">
                                        <DropdownToggle
                                            nav
                                            caret
                                            style={{ color: galaxyKids.textColor }}
                                        >
                                            <UserOutlined
                                                style={{ fontSize: "25px", color: galaxyKids.textColor }}
                                            />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {!localStorage.getItem("parentId") && (
                                                <>
                                                    <DropdownItem>Account Settings</DropdownItem>
                                                    <DropdownItem onClick={() => navigate("/profile")}>
                                                        Your Profile
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                </>
                                            )}
                                            <DropdownItem onClick={() => handleLogout()}>
                                                Sign Out
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </>
                            ) : (
                                <>
                                    {/* <button className='btn btn-outline-primary ms-2' data-bs-toggle="modal" data-bs-target="#parentLoginModal" >Parent Login</button> */}
                                    <button className='btn btn-outline-primary ms-2' onClick={() => navigate("/login")} >Login</button>
                                    <button className='btn btn-primary ms-2' onClick={() => navigate("/register")} >Sign up</button>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="modal fade create-tutor-modal" id="parentLoginModal" tabIndex="-1" aria-labelledby="parentLoginModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex align-items-center justify-content-between gap-2 pb-3 mb-4 border-bottom'>
                                <h5 className="modal-title fw-semibold" id="locationModalLabel"> {isLogin ? `Login as Parent` : `Register as Parent`} </h5>
                                <button type="button" ref={closeButtonRef} className="p-0 border-0 bg-transparent" data-bs-dismiss="modal" aria-label="Close">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0003 29.3332C23.3337 29.3332 29.3337 23.3332 29.3337 15.9998C29.3337 8.6665 23.3337 2.6665 16.0003 2.6665C8.66699 2.6665 2.66699 8.6665 2.66699 15.9998C2.66699 23.3332 8.66699 29.3332 16.0003 29.3332Z" stroke="#101820" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.2266 19.7734L19.7732 12.2267" stroke="#101820" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.7732 19.7734L12.2266 12.2267" stroke="#101820" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="w-100">
                                    <div className="card-body">
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        {isLogin ? (
                                            <Form form={form} onFinish={handleLoginSubmit} initialValues={{ email: "", password: "" }} >
                                                <Form.Item name="email" rules={[{ required: true, message: "Please enter your email" },]} >
                                                    <Input
                                                        className="shadow p-3 bg-white rounded"
                                                        type="email"
                                                        placeholder="Enter Your Email"
                                                    />
                                                </Form.Item>
                                                <Form.Item name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter your password",
                                                        },
                                                    ]} >
                                                    <Input.Password
                                                        className="shadow p-3 bg-white rounded"
                                                        placeholder="Enter Your Password"
                                                    />
                                                </Form.Item>
                                                <p
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {/* <Link
                                                    to={"/forgotpassword"}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    Forgot password?
                                                </Link> */}
                                                </p>

                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        className="shadow p-3 rounded"
                                                        style={{
                                                            backgroundColor: galaxyKids.textOrange,
                                                            color: "white",
                                                            border: "none",
                                                            width: "50%",
                                                            height: "50px",
                                                        }}
                                                        htmlType="submit"
                                                    >
                                                        LOGIN
                                                    </Button>
                                                </div>
                                            </Form>
                                        ) : (
                                            <Form
                                                form={form}
                                                onFinish={handleSubmit}
                                                initialValues={{
                                                    name: "",
                                                    first_name: "",
                                                    last_name: "",
                                                    email: "",
                                                    password: "",
                                                    language: "",
                                                    location: "",
                                                }}>
                                                <Form.Item
                                                    name="first_name"
                                                    rules={[
                                                        { required: true, message: "Please enter your first name" },
                                                    ]}
                                                >
                                                    <Input
                                                        className="shadow p-3 bg-white rounded placeholder-gray"
                                                        placeholder="Enter Your First Name"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="last_name"
                                                    rules={[
                                                        { required: true, message: "Please enter your last name" },
                                                    ]}
                                                >
                                                    <Input
                                                        className="shadow p-3 bg-white rounded placeholder-gray"
                                                        placeholder="Enter Your Last Name"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="email"
                                                    rules={[
                                                        { required: true, message: "Please enter your email" },
                                                        {
                                                            type: "email",
                                                            message: "Please enter a valid email",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="shadow p-3 bg-white rounded"
                                                        placeholder="Enter Your Email"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter your password",
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        className="shadow p-3 bg-white rounded"
                                                        placeholder="Enter Your Password"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="confirmPassword"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please confirm your password",
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue("password") === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "The two passwords that you entered do not match"
                                                                    )
                                                                );
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password
                                                        className="shadow p-3 bg-white rounded"
                                                        placeholder="Confirm Password"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="language"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select your language skills",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className="shadow p-3 bg-white rounded"
                                                        value={languageOptions.find(option => option.value === form.getFieldValue('language'))}
                                                        options={languageOptions}
                                                        placeholder="Please select your Native language"
                                                        onChange={value => form.setFieldsValue({ language: value })}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="location"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter location",
                                                        }
                                                    ]}
                                                >
                                                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                                        <input style={{ border: "none", }} type="text" placeholder="Search location" className="shadow p-3 bg-white rounded w-100" />
                                                    </Autocomplete>
                                                </Form.Item>
                                                <p className="text-center">
                                                    By Registering, you agree to our&nbsp;
                                                    <span style={{ color: galaxyKids.textColor }}>
                                                        User Agreement
                                                    </span>
                                                    &nbsp; And&nbsp;
                                                    <span style={{ color: galaxyKids.textColor }}>
                                                        Privacy Policy
                                                    </span>
                                                </p>
                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        htmlType="submit"
                                                        className="shadow p-3 rounded"
                                                        style={{
                                                            backgroundColor: galaxyKids.textOrange,
                                                            color: "#ddd",
                                                            border: "none",
                                                            width: "50%",
                                                            height: "50px",
                                                        }}
                                                    >
                                                        CREATE ACCOUNT
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                        <div className="text-center mt-3">
                                            <a
                                                href="javascript:void(0)"
                                                className="btn-link"
                                                onClick={() => setIsLogin(!isLogin)}
                                            >
                                                {isLogin ? "Don't have an account? Register" : "Already have an account? Login"}
                                            </a>
                                        </div>

                                        <div className="mb-3">
                                            <Divider plain>OR</Divider>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div
                                                style={{ height: "auto" }}
                                                className=""
                                            >
                                                <GoogleLoginButton onSuccess={handleSuccess} onError={handleError} isLogin="parent" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
